import { type FC } from 'react';
import { Button, List, SystemIcon, Typography } from '@volvo/vce-uikit';
import { ButtonContainer, StyledListItem } from '../../styles';

type Props = {
  addDisabled: boolean;
  disabledReason: string;
  header: string;
  isSet: boolean;
  onClick: (state: boolean) => void;
  removeText: string;
  addText: string;
};

export const Property: FC<Props> = ({
  addDisabled,
  disabledReason,
  header,
  isSet,
  onClick,
  removeText,
  addText,
}) => (
  <>
    <Typography style={{ marginBottom: 16 }}>{header}</Typography>
    {addDisabled ? <Typography>{disabledReason}</Typography> : null}
    {isSet ? (
      <List>
        <StyledListItem>
          <Typography>{removeText}</Typography>
          <SystemIcon
            style={{ cursor: 'pointer' }}
            icon="close"
            onClick={() => onClick(false)}
            data-testid="property-test-remove-icon"
          />
        </StyledListItem>
      </List>
    ) : (
      <ButtonContainer>
        <Button
          disabled={addDisabled}
          variant="secondary"
          onClick={() => onClick(true)}
          data-testid="property-test-add-button"
        >
          {addText}
        </Button>
      </ButtonContainer>
    )}
  </>
);
