import styled from '@emotion/styled';
import { ListItem } from '@volvo/vce-uikit';
import { theme } from '../../../../../theme';

export const Container = styled.div`
  height: 50px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid ${theme.colors.borderSubtle};
`;

export const ButtonContainer = styled.div`
  float: right;
  justify-content: end;
  margin-top: 20px;
`;

export const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
`;
